<div fxLayout="column">
  <form autocomplete="off" #activityForm="ngForm">
    <div fxLayout="row" style="margin-bottom: 10px">
      <app-editor-text
        fxFlex="100"
        name="txtUrlExpression"
        [creationMode]="true"
        [(ngModel)]="urlExpression"
        [config]="{
          isSimpleValue: true,
          isCode: true,
          required: true,
          hintLimit: 100,
          customDisplayName: 'key_urlExpression',
          updateOn: updateOnBlure,
          validationKey: 'workflow/restapicall/urlexpression'
        }"
        appExpressionValidator
      ></app-editor-text>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
      <mat-form-field fxFlex="50">
        <mat-label>{{ 'key_method' | translate }}</mat-label>
        <mat-select
          [(ngModel)]="method"
          [ngModelOptions]="{ standalone: true }"
        >
          <mat-option value="GET">GET</mat-option>
          <mat-option value="DELETE">DELETE</mat-option>
          <mat-option value="PATCH">PATCH</mat-option>
          <mat-option value="POST">POST</mat-option>
          <mat-option value="PUT">PUT</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-checkbox
        fxFlex="50"
        [(ngModel)]="asyncCallBack"
        [ngModelOptions]="{ standalone: true }"
        matTooltip="{{ 'key_asyncCallDes' | translate }}"
        >{{ 'key_asyncCall' | translate }}</mat-checkbox
      >
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
      <mat-form-field fxFlex="50">
        <mat-label>{{ 'key_authenticationType' | translate }}</mat-label>
        <mat-select
          [(ngModel)]="authenticationType"
          [ngModelOptions]="{ standalone: true }"
        >
          <mat-option value="Anonymous">Anonymous</mat-option>
          <mat-option value="AzureAdClientSecret"
            >Azure AD with client secret</mat-option
          >
          <mat-option value="ManagedIdentity">Managed identity</mat-option>
          <mat-option value="OAuth2ClientSecret"
            >OAuth2 with client secret</mat-option
          >
        </mat-select>
      </mat-form-field>
      <div fxFlex="50">
        <app-editor-text
          *ngIf="
            authenticationType === 'ManagedIdentity' ||
            authenticationType === 'AzureAdClientSecret'
          "
          fxFlex="100"
          name="txtAzureResourceId"
          [(ngModel)]="azureResourceId"
          [config]="{
            isSimpleValue: true,
            isCode: true,
            hintLimit: 100,
            customDisplayName: 'key_azureResourceID',
            updateOn: updateOnBlure,
            validationKey: 'workflow/restapicall/azureResourceId'
          }"
          appExpressionValidator
          style="margin-top: -3px"
        ></app-editor-text>
        <app-editor-text
          *ngIf="authenticationType === 'OAuth2ClientSecret'"
          fxFlex="100"
          name="txtOAuth2ClientSecret"
          [(ngModel)]="oauth2Scope"
          [config]="{
            isSimpleValue: true,
            isCode: true,
            hintLimit: 100,
            customDisplayName: 'Scope',
            updateOn: updateOnBlure,
            validationKey: 'workflow/restapicall/oauth2Scope'
          }"
          appExpressionValidator
          style="margin-top: -3px"
        ></app-editor-text>
      </div>
    </div>
    <div
      *ngIf="authenticationType === 'AzureAdClientSecret'"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="20px"
    >
      <app-editor-text
        fxFlex="100"
        name="txtAzureTenantId"
        [(ngModel)]="azureTenantId"
        [config]="{
          isSimpleValue: true,
          isCode: true,
          hintLimit: 100,
          customDisplayName: 'Tenant ID',
          updateOn: updateOnBlure,
          validationKey: 'workflow/restapicall/azureTenantId'
        }"
        appExpressionValidator
        style="margin-top: -3px"
      ></app-editor-text>
    </div>
    <div
      *ngIf="authenticationType === 'OAuth2ClientSecret'"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="20px"
    >
      <app-editor-text
        fxFlex="100"
        name="txtOauth2TokenEndpoint"
        [(ngModel)]="oauth2TokenEndpoint"
        [config]="{
          isSimpleValue: true,
          isCode: true,
          hintLimit: 100,
          customDisplayName: 'Token endpoint',
          updateOn: updateOnBlure,
          validationKey: 'workflow/restapicall/oauth2TokenEndpoint'
        }"
        appExpressionValidator
        style="margin-top: -3px"
      ></app-editor-text>
    </div>
    <div
      *ngIf="
        authenticationType === 'AzureAdClientSecret' ||
        authenticationType === 'OAuth2ClientSecret'
      "
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="20px"
    >
      <app-editor-text
        fxFlex="100"
        name="txtOauth2ClientId"
        [(ngModel)]="oauth2ClientId"
        [config]="{
          isSimpleValue: true,
          isCode: true,
          hintLimit: 100,
          customDisplayName: 'Client ID',
          updateOn: updateOnBlure,
          validationKey: 'workflow/restapicall/oauth2ClientId'
        }"
        appExpressionValidator
        style="margin-top: -3px"
      ></app-editor-text>
    </div>
    <div
      *ngIf="
        authenticationType === 'AzureAdClientSecret' ||
        authenticationType === 'OAuth2ClientSecret'
      "
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="20px"
    >
      <app-editor-text
        fxFlex="100"
        name="txtOauth2ClientSecret"
        [(ngModel)]="oauth2ClientSecret"
        [config]="{
          isSimpleValue: true,
          isCode: true,
          hintLimit: 100,
          customDisplayName: 'Client secret',
          updateOn: updateOnBlure,
          validationKey: 'workflow/restapicall/oauth2ClientSecret'
        }"
        appExpressionValidator
        style="margin-top: -3px"
      ></app-editor-text>
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="20px"
      style="margin-top: 10px"
    >
      <mat-form-field fxFlex="50">
        <mat-label>{{ 'key_contentType' | translate }}</mat-label>
        <mat-select
          [(ngModel)]="contentType"
          [ngModelOptions]="{ standalone: true }"
        >
          <mat-option value="application/json">application/json</mat-option>
          <mat-option value="text/plain">text/plain</mat-option>
          <mat-option value="text/json">text/json</mat-option>
          <mat-option value="application/json-path+json"
            >application/json-path+json</mat-option
          >
          <mat-option value="application/*+json">application/*+json</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field
        fxFlex="50"
        matTooltip="{{ 'key_responseKeyDes' | translate }}"
      >
        <mat-label>{{ 'key_responseKey' | translate }}</mat-label>
        <input
          matInput
          [(ngModel)]="responseKey"
          [ngModelOptions]="{ standalone: true }"
        />
      </mat-form-field>
    </div>
  </form>
  <div style="margin-top: 10px; font-weight: 400">
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="20px"
      style="margin-bottom: 10px"
    >
      <div
        fxFlex="none"
        class="api-setting-base"
        [ngClass]="{ 'api-setting': showHeaders }"
        (click)="onSwitchSetting('headers')"
      >
        {{ 'key_requestHeaders' | translate }}
      </div>
      <div
        fxFlex="none"
        class="api-setting-base"
        [ngClass]="{ 'api-setting': showQueries }"
        (click)="onSwitchSetting('queries')"
      >
        {{ 'key_requestQueries' | translate }}
      </div>
      <div
        fxFlex="none"
        class="api-setting-base"
        [ngClass]="{ 'api-setting': showBody }"
        (click)="onSwitchSetting('body')"
      >
        {{ 'key_requestBody' | translate }}
      </div>
      <div
        fxFlex="none"
        class="api-setting-base"
        [ngClass]="{ 'api-setting': showResponseCodes }"
        (click)="onSwitchSetting('codes')"
      >
        {{ 'key_responseCode' | translate }}
      </div>
    </div>
    <div *ngIf="showHeaders">
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="20px"
        *ngFor="let header of headerDic"
        style="margin-top: 2px; font-size: 12px; padding: 5px 10px"
        class="accent-text-color background-bg-color"
      >
        <div fxFlex="1 1 20">{{ header.key }}</div>
        <div fxFlex="1 1 80">{{ header.value }}</div>
        <div fxFlex="0 0 20px">
          <mat-icon
            style="cursor: pointer; font-size: 18px"
            class="warn-color"
            (click)="onRemoveHeader(header.key)"
            >delete_outline</mat-icon
          >
        </div>
      </div>
      <form autocomplete="off" #headerForm="ngForm">
        <div
          fxlayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="20px"
          style="margin-top: 10px"
        >
          <app-editor-text
            fxFlex="1 1 20"
            style="font-size: 0.85em"
            name="txtHeaderKey"
            [(ngModel)]="headerKey"
            [config]="{
              isSimpleValue: true,
              hintLimit: 40,
              customDisplayName: 'key_key',
              updateOn: updateOnBlure,
              validation: '^[a-z0-9]+$'
            }"
          ></app-editor-text>
          <app-editor-text
            fxFlex="1 1 80"
            style="font-size: 0.85em"
            name="txtHeaderValue"
            [(ngModel)]="headerValue"
            [config]="{
              isSimpleValue: true,
              hintLimit: 60,
              customDisplayName: 'key_value',
              updateOn: updateOnBlure,
              validationKey: 'workflow/restapicall/headerexpressions'
            }"
            appExpressionValidator
          ></app-editor-text>
          <button
            fxFlex="none"
            mat-stroked-button
            color="primary"
            style="height: 30px; line-height: 30px; font-size: 12px"
            [disabled]="!headerKey || !headerValue || headerForm.invalid"
            (click)="onAddHeader()"
          >
            {{ 'key_add' | translate }}
          </button>
        </div>
      </form>
    </div>
    <div *ngIf="showQueries">
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="20px"
        *ngFor="let query of queryExpression"
        style="margin-top: 2px; font-size: 12px; padding: 5px 10px"
        class="accent-text-color background-bg-color"
      >
        <div fxFlex="1 1 20">{{ query.key }}</div>
        <div fxFlex="1 1 80">{{ query.value }}</div>
        <div fxFlex="0 0 20px">
          <mat-icon
            style="cursor: pointer; font-size: 18px"
            class="warn-color"
            (click)="onRemoveQuery(query.key)"
            >delete_outline</mat-icon
          >
        </div>
      </div>
      <form autocomplete="off" #queryForm="ngForm">
        <div
          fxlayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="20px"
          style="margin-top: 10px"
        >
          <app-editor-text
            fxFlex="1 1 20"
            style="font-size: 0.85em"
            name="txtQueryKey"
            [(ngModel)]="queryKey"
            [config]="{
              isSimpleValue: true,
              hintLimit: 40,
              customDisplayName: 'key_key',
              updateOn: updateOnBlure,
              validation: '^[a-z0-9]+$'
            }"
          ></app-editor-text>
          <app-editor-text
            fxFlex="1 1 80"
            style="font-size: 0.85em"
            name="txtQueryValue"
            [(ngModel)]="queryValue"
            [config]="{
              isSimpleValue: true,
              hintLimit: 60,
              customDisplayName: 'key_value',
              updateOn: updateOnBlure,
              validationKey: 'workflow/restapicall/queryexpressions'
            }"
            appExpressionValidator
          ></app-editor-text>
          <button
            fxFlex="none"
            mat-stroked-button
            color="primary"
            style="height: 30px; line-height: 30px; font-size: 12px"
            [disabled]="!queryKey || !queryValue || queryForm.invalid"
            (click)="onAddQuery()"
          >
            {{ 'key_add' | translate }}
          </button>
        </div>
      </form>
    </div>
    <div *ngIf="showBody">
      <mat-form-field appearance="fill" style="width: 100%">
        <mat-label>{{ 'key_requestBody' | translate }}</mat-label>
        <textarea
          matInput
          [(ngModel)]="bodyExpression"
          [ngModelOptions]="{ standalone: true }"
          [rows]="8"
        ></textarea>
      </mat-form-field>
    </div>
    <div *ngIf="showResponseCodes" fxLayout="column">
      <div fxLayout="row wrap">
        <div
          *ngFor="let code of expectedResponseCodes; let i = index"
          fxLayout="row"
          fxLayoutAlign="start center"
          class="accent-text-color background-bg-color"
          style="padding: 5px 3px; margin-bottom: 5px; margin-right: 10px"
        >
          <div style="margin-right: 5px">{{ code }}</div>
          <span
            *ngIf="expectedResponseCodes && expectedResponseCodes.length > 1"
            class="material-icons-outlined warn-color"
            style="font-size: 15px; cursor: pointer"
            (click)="onRemoveExpectedCode(i)"
          >
            clear
          </span>
        </div>
      </div>
      <div
        fxlayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="20px"
        style="margin-top: 10px"
      >
        <mat-form-field fxFlex="1 1 50">
          <input
            matInput
            type="number"
            min="0"
            [placeholder]="'key_expectedResponseCode' | translate"
            [(ngModel)]="expectedCode"
            [ngModelOptions]="{ standalone: true }"
            matTooltip="{{ 'key_expectedResponseCodeDes' | translate }}"
          />
        </mat-form-field>
        <button
          fxFlex="none"
          mat-stroked-button
          color="primary"
          style="height: 30px; line-height: 30px; font-size: 12px"
          [disabled]="!expectedCode"
          (click)="onAddExpectedCode()"
        >
          {{ 'key_add' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
