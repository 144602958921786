import { MatFormFieldAppearance } from '@angular/material/form-field';
import { WindowRef } from '@progress/kendo-angular-dialog';
import { Observable } from 'rxjs';
import { TabViewComponent } from 'src/app/tab-view/tab-view.component';
import { PopupWizardComponent } from '../components/popup-wizard/popup-wizard.component';
import { ResourceColumnConfig } from './componentContract.model';

export enum AuthMode {
  basic = 'basic',
  windows = 'windows',
  azure = 'azure',
}

export enum SystemType {
  basic = 'basic',
  windows = 'windows',
  mix = 'mix',
  onPrem = 'onPrem', // obsoleted, same effect as mix
  azure = 'azure',
  cloud = 'cloud', // obsoleted, same effect as azure
  link = 'link',
}

export enum ModelUpdateMode {
  change = 'change',
  blur = 'blur',
  submit = 'submit',
}

export class SidebarItem {
  name: string;
  path: string;
  title: string;
  icon: string;
  enabled: boolean;
}

export class BroadcastEvent {
  name: string;
  parameter?: any;
  payload?: any;

  constructor(name: string, param?: any, payload?: any) {
    this.name = name;
    this.parameter = param;
    this.payload = payload;
  }
}

export class EditorEvent extends BroadcastEvent {
  attributeName: string;
  resourceId: string;
  resourceType: string;

  constructor(
    name: string,
    attrName: string,
    id: string,
    type: string,
    param?: any
  ) {
    super(name, param);
    this.attributeName = attrName;
    this.resourceId = id;
    this.resourceType = type;
  }
}

export class ObjectViewEvent {
  type: string;
  editorEvent?: EditorEvent;
  tabView?: TabViewComponent;
  parameter?: any;

  constructor(
    type: string,
    editorEvent?: EditorEvent,
    tabView?: TabViewComponent,
    parameter?: any
  ) {
    this.type = type;
    this.editorEvent = editorEvent;
    this.tabView = tabView;
    this.parameter = parameter;
  }
}

export class TabViewEvent {
  type: string;
  tabView?: any;

  constructor(type: string, tabView: any) {
    this.type = type;
    this.tabView = tabView;
  }
}

export class MenuEvent {
  type: string;
  viewName?: string;
  itemName?: string;
  parameter?: any;
  menu?: any;
  resourceName?: string;

  constructor(
    type: string,
    viewName?: string,
    itemName?: string,
    parameter?: any,
    menu?: any,
    resourceName?: string
  ) {
    this.type = type;
    this.viewName = viewName;
    this.itemName = itemName;
    this.parameter = parameter;
    this.menu = menu;
    this.resourceName = resourceName;
  }
}

export class WizardEvent {
  type: string;
  wizard?: PopupWizardComponent;
  windowRef?: WindowRef;
  parameter?: any;

  constructor(
    type: string,
    wizard?: PopupWizardComponent,
    windowRef?: WindowRef,
    parameter?: any
  ) {
    this.type = type;
    this.wizard = wizard;
    this.windowRef = windowRef;
    this.parameter = parameter;
  }
}

export class MultivalueParameter {
  attribute: string;
  values: Array<string>;
  editor: any;
  resourceID: string;
  resourceType?: string;
  resourceName?: string;
}

export class System {
  name: string;
  displayName?: string;
  type: SystemType;
  description: string;
  icon: string;
  enabled? = true;
  useVerifiedId? = false;
  // eslint-disable-next-line @typescript-eslint/ban-types
  config?: object;
}

export class AuthUser {
  DisplayName?: string;
  ObjectID?: string;
  AccountName?: string;
  AuthenticationMode?: AuthMode;
  AccessToken?: string;
  AccessConnection?: string;
}

export class ConnectedUser {
  public baseAddress: string;
  public name: string;
  public domain: string;
  public password: string;
}

export class BasicResource {
  public DisplayName?: string;
  public ObjectID?: string;
  public ObjectType?: string;

  public constructor(resource: BasicResource | Resource) {
    this.DisplayName = resource.DisplayName;
    this.ObjectID = resource.ObjectID;
    this.ObjectType = resource.ObjectType;
  }
}

export class TypeResource {
  description?: string;
  displayName: string;
  systemName: string;
}

export class AttributeResource {
  description?: string;
  displayName?: string;
  multivalued?: boolean;
  required?: boolean;
  stringRegex?: string;
  integerMinimum?: number;
  integerMaximum?: number;
  systemName?: string;
  dataType?: string;
  permissionHint?: string;
  // value?: BasicResource | object | any;
  // values?: BasicResource[] | object[] | any[];
  value?: any;
  values?: any[];
  // idabus only properties
  readonly?: boolean;
  isinternal?: boolean;
  multivalueReferenceOptions?: string;
  xPathPersistenceOptions?: string;

  constructor() {
    this.description = null;
    this.displayName = null;
    this.multivalued = false;
    this.required = false;
    this.stringRegex = null;
    this.integerMaximum = null;
    this.integerMinimum = null;
    this.systemName = null;
    this.dataType = 'string';
    this.permissionHint = '';
    this.value = '';
    this.values = [];
    // idabus only properties
    this.readonly = false;
    this.isinternal = false;
    this.multivalueReferenceOptions = null;
    this.xPathPersistenceOptions = null;
  }
}

export interface AnyProperties {
  // eslint-disable-next-line @typescript-eslint/ban-types
  [prop: string]: AttributeResource | BasicResource | object | any;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type Resource = AnyProperties & {};

export class ResourceSet {
  public hasMoreItems: boolean;
  public totalCount: number;
  public results: Resource[];
  public continuationToken?: string;
}

export class AttributesToShow {
  public main: string;
  public secondary?: string[];
  public unitWidth?: number;
}

export class SearchScope {
  name?: string;
  type?: string;
  text?: string;
  icon?: string;
  enabled?: boolean;
  navigationKey?: string;
  typeQuery?: string;
  query?: string;
  exactQuery?: string;
  displayedAttributesInSearch?: AttributesToShow;
  attributes?: Array<string>;
  availableAttributes?: Array<string>;
  columns?: Array<ResourceColumnConfig>;
  selectable?: boolean;
  tooltipAttribute?: string;
  isLoading?: boolean;
  isSubScope?: boolean;
  results?: ResourceSet;
  defaultLinkAction?: string;
  linkActions?: Array<string>;
  pageSize?: number;
  includeCount?: boolean;
  initSort?: Array<{ field: string; dir: string }>;

  constructor(init?: Partial<SearchScope>) {
    Object.assign(this, init);
  }
}

export class SearchDef {
  exactSearch?: boolean;
  text?: string;
  isGuid?: boolean;
}

export class SelectableItem {
  selected?: boolean;
}

export class HistoryItem extends SelectableItem {
  text?: string;
}

export class RecentItem extends SelectableItem {
  icon?: string;
  id?: string;
  resource?: Resource;
  navigationKey?: string;
}

export class ActionMenuItem {
  name: string;
  icon?: string;
  text?: string;
  tooltip?: string;
  hint?: string;
  color?: string;
  disabled?: boolean;
  hidden?: boolean;
  hideIfNoPermission?: boolean;
  permissionSets?: Array<string>;
}

export class XPathAttribute {
  attributeName: string;
}

export class XPathParameter {
  operator: string;
  parameters: Array<
    string | number | XPathAttribute | XPathParameter | XPathQuery
  > = new Array<
    string | number | XPathAttribute | XPathParameter | XPathQuery
  >();
}

export class XPathStep {
  name: string;
  predicate?: XPathParameter;
}

export class XPathQuery {
  steps: Array<XPathStep> = new Array<XPathStep>();
  isNull?: boolean = false;
}

export class ResourceEntry {
  target?: string;
  valueexpression?: string;
  allowNull?: boolean;
}

export class ApiDef {
  method: string;
  path: string;
  pathContinue?: string;
  pathRefresh?: string;
  header?: any;
  param?: any;
  body?: any;
}

export class WFActivity {
  id?: string;
  type?: string;
  displayname?: string;
  description?: string;
  isenabled?: boolean;
  continueonerror?: boolean;
  xpathqueries?: any;
  executioncondition?: string;
  workflowdataexpressions?: any;
  showdebuginfo?: boolean;

  constructor(type: string) {
    this.type = type;
    this.displayname = '';
    this.description = '';
    this.isenabled = true;
    this.continueonerror = false;
    this.executioncondition = '';
  }
}

export class WFActivityAddDelay extends WFActivity {
  delay?: string;

  constructor() {
    super('AddDelay');
    this.displayname = 'Add Delay';
    this.delay = '00:00:00';
  }
}

export class WFActivityApproval extends WFActivity {
  approvaltitle?: string;
  approvaldescription?: string;
  durationbystage?: any[];
  approversbystage?: any[];
  approvalthreshold?: string;
  pendingapprovalemailtemplate?: string;
  pendingapprovalescalationemailtemplate?: string;
  completedapprovalemailtemplate?: string;
  rejectedrequestemailtemplate?: string;
  timedoutrequestemailtemplate?: string;

  constructor(type?: string) {
    super(type ?? 'Approval');
    this.displayname = 'Approval';
    this.approvaltitle = '';
    this.approvaldescription = '';
    this.durationbystage = [];
    this.approversbystage = [];
    this.approvalthreshold = '1';
    this.pendingapprovalemailtemplate = `/emailTemplate[displayname='pending']`;
    this.pendingapprovalescalationemailtemplate = `/emailTemplate[displayname='escalation']`;
    this.completedapprovalemailtemplate = `/emailTemplate[displayname='completed']`;
    this.rejectedrequestemailtemplate = `/emailTemplate[displayname='rejected']`;
    this.timedoutrequestemailtemplate = `/emailTemplate[displayname='timeout']`;
  }
}

export class WFActivityManualTask extends WFActivityApproval {
  responseKey?: string;
  disableautoselfapproval?: boolean;
  formid?: string;
  formdata?: { [name: string]: string };
  showformimmediately?: boolean;
  taskstartmessage?: string;
  tasktype?: string;
  donotwaitforresponse?: boolean;

  constructor() {
    super('ManualTask');
    this.displayname = 'Manual Task';
    this.pendingapprovalemailtemplate = '';
    this.pendingapprovalescalationemailtemplate = '';
    this.completedapprovalemailtemplate = '';
    this.rejectedrequestemailtemplate = '';
    this.timedoutrequestemailtemplate = '';
    this.responseKey = '';
    this.disableautoselfapproval = true;
    this.formid = '';
    this.formdata = {};
    this.showformimmediately = false;
    this.taskstartmessage = '';
    this.tasktype = '';
    this.donotwaitforresponse = false;
  }
}

export class WFActivityCreateResource extends WFActivity {
  disablepreupdatetriggers?: boolean;
  disablepostupdatetriggers?: boolean;
  createresourceentries?: ResourceEntry[];

  constructor() {
    super('CreateResource');
    this.displayname = 'Create Resource';
    this.disablepreupdatetriggers = true;
    this.disablepostupdatetriggers = false;
    this.createresourceentries = [];
  }
}

export class WFActivityDeleteResource extends WFActivity {
  disablepreupdatetriggers?: boolean;
  disablepostupdatetriggers?: boolean;
  deletiontarget?: string;

  constructor() {
    super('DeleteResources');
    this.displayname = 'Delete Resource';
    this.disablepreupdatetriggers = true;
    this.disablepostupdatetriggers = false;
    this.deletiontarget = '';
  }
}

export class WFActivityForEach extends WFActivity {
  listexpression?: string;
  currentitemkey?: string;
  activities?: WFActivity[];

  constructor() {
    super('ForEach');
    this.displayname = 'For-Each';
    this.listexpression = '';
    this.currentitemkey = '';
    this.activities = [];
  }
}

export class WFActivityIfThenElse extends WFActivity {
  activitiestrue?: WFActivity[];
  activitiesfalse?: WFActivity[];

  constructor() {
    super('IfThenElse');
    this.displayname = 'If-Then-Else';
    this.activitiestrue = [];
    this.activitiesfalse = [];
  }
}

export class WFActivityRestApiCall extends WFActivity {
  method?: string;
  contenttype?: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  headerexpressions?: object;
  urlexpression?: string;
  queryexpressions?: Array<{ key: string; value: string }>;
  bodyexpression?: any;
  responseKey?: string;
  expectedresponsecodes?: number[];
  asynccallback?: boolean;
  authenticationType?: string;
  azureResourceId?: string;
  oauth2ClientId?: string;
  oauth2ClientSecret?: string;
  oauth2Scope?: string;
  oauth2TokenEndpoint?: string;
  azureTenantId?: string;

  constructor() {
    super('RestApiCall');
    this.displayname = 'Web API Call';
    this.method = 'GET';
    this.contenttype = 'application/json';
    this.headerexpressions = {};
    this.urlexpression = '';
    this.queryexpressions = [];
    this.bodyexpression = '';
    this.responseKey = '';
    this.expectedresponsecodes = [
      200, 201, 202, 203, 204, 205, 206, 207, 208, 226,
    ];
    this.asynccallback = false;
    this.authenticationType = 'Anonymous';
    this.azureResourceId = '';
    this.oauth2ClientId = '';
    this.oauth2ClientSecret = '';
    this.oauth2Scope = '';
    this.oauth2TokenEndpoint = '';
    this.azureTenantId = '';
  }
}

export class WFActivitySendEmail extends WFActivity {
  emailtemplate?: string;
  to?: string;
  cc?: string;
  bcc?: string;
  importance?: string;

  constructor() {
    super('SendEmail');
    this.displayname = 'Send Email';
    this.emailtemplate = '';
    this.to = '';
    this.cc = '';
    this.bcc = '';
    this.importance = 'Normal';
  }
}

export class WFActivitySequential extends WFActivity {
  activities?: WFActivity[];

  constructor() {
    super('Sequential');
    this.displayname = 'Sequential';
    this.activities = [];
  }
}

export class WFActivityUpdateResource extends WFActivity {
  disablepreupdatetriggers?: boolean;
  disablepostupdatetriggers?: boolean;
  updateresourcesentries?: ResourceEntry[];

  constructor() {
    super('UpdateResources');
    this.displayname = 'Update Resource';
    this.disablepreupdatetriggers = true;
    this.disablepostupdatetriggers = false;
    this.updateresourcesentries = [];
  }
}

export class WFActivityTemplate extends WFActivity {
  workflowtemplateid?: string;
  inputparameters?: any;

  constructor() {
    super('Workflow');
    this.displayname = 'Workflow Template';
    this.workflowtemplateid = '';
    this.inputparameters = {};
  }
}

export class WFActivityDataFlow extends WFActivity {
  dataflowruleid?: string;
  dataflowchange?: string;
  disablefurtherdataflowtriggering?: boolean;

  constructor() {
    super('Dataflow');
    this.displayname = 'Recalculate Dataflow Rule';
    this.dataflowruleid = '';
    this.dataflowchange = 'CompleteRecalculation';
    this.disablefurtherdataflowtriggering = true;
  }
}

export class WFActivityStatus extends WFActivity {
  status?: string;

  constructor() {
    super('Status');
    this.displayname = 'Status';
    this.status = 'Success';
  }
}

export class WFActivityPlaceholder extends WFActivity {
  originalactivity?: string;

  constructor() {
    super('Placeholder');
    this.displayname = 'Placeholder';
    this.originalactivity = '';
  }
}

export class WFActivitySimulation extends WFActivity {
  activities?: WFActivity[];

  constructor() {
    super('Simulation');
    this.displayname = 'Simulation';
    this.activities = [];
  }
}

export class WFActivitySimulationUpdate extends WFActivity {
  waitforcompletion?: boolean;
  maxwaitbeforecontinuinginbackground?: string;
  waitingforcompletioninbackgroundmessage?: string;

  constructor() {
    super('SimulateRequestedUpdate');
    this.displayname = 'Simulate Requested Update';
    this.waitforcompletion = true;
    this.maxwaitbeforecontinuinginbackground = '00:00:10';
    this.waitingforcompletioninbackgroundmessage = '';
  }
}

export class WFActivityWorkflowData extends WFActivity {
  constructor() {
    super('WorkflowData');
    this.displayname = 'Workflow Data';
  }
}

export class Activity {
  type?: string;
  id?: string;
  isenabled?: boolean;
  displayname?: string;
  description?: string;
  activities?: Array<Activity>;
  display?: boolean;
  runonpreviousstatuscondition?: Array<string>;
  actortype?: string;
  actorexpression?: string;
  executioncondition?: string;
}

export class UpdateActivity extends Activity {
  xpathqueries?: Array<{ key: string; value: string }>;
  updateresourcesentries?: Array<{
    target: string;
    valueexpression: string;
    allownull: boolean;
  }>;
}

export class ForeachActivity extends Activity {
  currentitemkey?: string;
  listexpression?: string;
  updatewhileiterating?: boolean;
  continueonerror?: boolean;
}

export class CallRestActivity extends Activity {
  method?: string;
  urlexpression?: string;
  headerexpressions?: Array<{ key: string; value: string }>;
  queryexpressions?: Array<{ key: string; value: string }>;
  bodyexpression?: { [key: string]: string };
  expressions?: Array<{ key: string; value: string }>;
}

export class PopupController {
  text?: string;
  value?: any;
  hint?: string;
  type?: string;
  width?: number;
  options?: Array<any>;
  validation?: string;
  required?: boolean;
  focused?: boolean;
  visible?: boolean;
  config?: any;
  rows?: number;

  constructor() {
    this.text = '';
    this.value = undefined;
    this.hint = '';
    this.type = 'text';
    this.width = 100;
    this.options = [];
    this.validation = '';
    this.required = false;
    this.focused = false;
    this.visible = true;
    this.config = undefined;
    this.rows = undefined;
  }
}

export class PopupButtonController {
  text?: string;
  color?: string;

  constructor(text: string, color: string) {
    this.text = text;
    this.color = color;
  }
}

export class PopupConfig {
  title?: string;
  style?: MatFormFieldAppearance;
  width?: number;
  singleButton?: boolean;
  noButton?: boolean;
  confirmButton?: PopupButtonController;
  cancelButton?: PopupButtonController;
  data?: { [key: string]: PopupController };

  constructor() {
    this.title = '';
    this.style = 'standard';
    this.width = 420;
    this.singleButton = false;
    this.noButton = false;
    this.confirmButton = new PopupButtonController('key_ok', 'primary');
    this.cancelButton = new PopupButtonController('key_cancel', 'basic');
    this.data = {};
  }
}

export class WizardConfig {
  name?: string;
  displayName?: string;
  objectType?: string;
  type?: string;
  isCreation?: boolean;
  icon?: string;
  maxHeight?: number;
  canSubmitAtStep?: number;
  width?: number;
  sections?: Array<any>;
  navigationKey?: string;
  navigateToResource?: boolean;
  hideButtons?: boolean;
  submitButtonText?: string;
  cancelButtonText?: string;
}

export class BrandAttribute {
  name?: string;
  icon?: string;
  iconAlt?: string;
  text?: string;
  textAlt?: string;
  hidden?: boolean;
}

export class BrandConfig {
  // Setup
  attributes?: Array<BrandAttribute>;
  enabled?: boolean;
  mainAttribute?: string;
  photoAttribute?: string;
  secondaryAttribute?: string;
  showButtons?: boolean;
  useCustomComponent?: boolean;
  customComponentID?: string;
  customComponentData?: string;

  // Defaults
  constructor(enabled: boolean) {
    this.attributes = [];
    this.enabled = enabled;
    this.mainAttribute = 'DisplayName';
    this.photoAttribute = 'Photo';
    this.secondaryAttribute = '';
    this.showButtons = false;
    this.useCustomComponent = false;
    this.customComponentID = undefined;
    this.customComponentData = '';
  }
}

export class StructureConfig {
  enabled?: boolean;
  initQuery?: string;
  childrenQuery?: string;

  constructor(enabled: boolean) {
    this.enabled = enabled;
    this.initQuery = `/*[ObjectID='[#CurrentID]']`;
    this.childrenQuery = `/*[ocgParentRef='%ParentID%']`;
  }
}

export class StatusConfig {
  attributeName?: string;
  attributeValue?: string;
  color?: string;
  text?: string;
}

export class FormConfig {
  name?: string;
  objectType?: string;
  type?: string;
  sampleSetting?: boolean;
  attributesToLoad?: Array<string>;
  icon?: string;
  sections?: Array<any>;
  brandView?: BrandConfig;
  structureView?: StructureConfig;
  statuses?: Array<StatusConfig>;
}

export class RequestParameter {
  attribute?: string;
  attributeName?: string;
  action?: string;
  value?: string;
}

export class ApprovalResponse {
  createdTime?: string;
  creator?: string;
  decision?: string;
  reason?: string;
}

export class Approval {
  objectID?: string;
  duration?: string;
  status?: string;
  threshold?: number;
  approver?: Array<string>;
  responses?: Observable<Array<ApprovalResponse>>;
  expanded?: boolean;
}

export class ApprovalRequest {
  displayName?: string;
  title?: string;
  objectID?: string;
  target?: string;
  creator?: string;
  requestParameters?: Array<RequestParameter>;
  approvals?: Array<Approval>;
  content?: string;
  status?: string;
  createdTime?: string;
  showContent?: boolean;
  showDetail?: boolean;
  showResponse?: boolean;
}

export class ApprovalCloudRequest {
  title?: string;
  content?: string;
  approvals?: any[];
  approvalProcessDecision?: string;
  approvalProcessDisplayName?: string;
  requestDateTime?: string;
  requestorDisplayName?: string;
  requestorId?: string;
  targetDisplayName?: string;
  targetId?: string;
  targetObjectType?: string;
  attributeAssignments?: { [name: string]: string };
  previousAttributeAssignments?: { [name: string]: string };
  multivalueInsertions?: { [name: string]: Array<string> };
  multivalueRemovals?: { [name: string]: Array<string> };
  // appended ui properties
  attributeChanges?: Array<AttributeChange>;
  showContent?: boolean;
  showDetail?: boolean;
  showResponse?: boolean;
}

export class DataExchange {
  payload?: string;
  objectType?: string;
  content?: Array<BasicResource>;
}

export class AttributeChange {
  attribute?: string;
  name?: string;
  action?: string;
  type?: string;
  oldValue?: any;
  newValue?: any;
}

export class AttributeStyle {
  attribute: string;
  value?: any;
  icon?: string;
  color?: string;
  tip?: string;
}

export class TreeNode {
  DisplayName: string;
  ObjectID: string;
  ObjectType: string;
  Icon?: string;
  Color?: string;
  Tip?: string;
}

export class WorkflowDescription {
  id: string;
  type: string;
  displayname?: string;
  description?: string;
  isenabled?: boolean;
  activities?: Array<WorkflowDescription>;
  activitiestrue?: Array<WorkflowDescription>;
  activitiesfalse?: Array<WorkflowDescription>;
  debuginfotoenable?: Array<string>;
  debuginfotodisable?: Array<string>;
  hideContent?: boolean;
}

export class ActivityDescription {
  name?: string;
  description?: string;
  icon?: string;
  def?: any;
  isContainer?: boolean;
  isConditional?: boolean;
  multiActivities?: boolean;
  disabled?: boolean;
  onlyInSimulation?: boolean;
  forbiddenInSimulation?: boolean;
}

export class NavigationItem {
  displayName?: string;
  objectID?: string;
  objectType?: string;
  path?: string;
  readOnly?: boolean;
}

export class ExpressionValidationSpecification {
  isRequired = true;
  allowFunctionExpression = true;
  allowXPath = true;
  xPathMustBeSimple = true;
  allowInterpolatedString = true;
  allowedLookups: string[] = null;
  allowedStringFormats: string[] = null;
  defaultExpressionType?: string = undefined;
  currentExpressionType?: string = undefined;
  explanation?: string = undefined;
  interpretSemicolonAsListSeparator? = false;
  typeId? = '';
}

export class ExpressionValidationConfig {
  expression: string;
  validationSpecification: ExpressionValidationSpecification;
}

export class ExpressionValidationResult {
  isValid: boolean;
  explanation: string;
}

export class ValidationTag {
  name?: string;
  abbreviation?: string;
  description?: string;
  descLong?: string;
  isActive?: boolean;
  helpUrl?: string;
}

export class ValidationSetting {
  type?: string = '';
  tags?: Array<ValidationTag> = null;
  key?: string = null;
  exclusive?: boolean = true;
  canDeactivateAll?: boolean = false;
  allowedLookups?: Array<string> = null;
  allowedStringFormats?: Array<string> = null;

  constructor(init?: Partial<ValidationSetting>) {
    Object.assign(this, init);
  }
}

export class ApiValidationConfig {
  url: string;
  method: 'GET' | 'POST';
  params?: any;
  body?: any;
}

export class SortOption {
  attribute: string;
  order: string;
}

export class ReferenceOption {
  reference: string;
  attributes: Array<string>;
}

export class HistoricalReportConfig {
  dateTimeUtc?: string;
  attributes: Array<string> = [];
  historicalAttributes: Array<string> = [];
  pageSize?: number;
  orderBy?: SortOption;
  resolveReferences?: Array<ReferenceOption>;
}

export class RefreshToken {
  name: string;
  token: string;
  path: string;
}

export class NotificationDef {
  enabled: boolean;
  name: string;
  query: string;
  isObject?: boolean;
  hideMessage?: boolean;
}

export class PullNotification {
  interval: number;
  entries: Array<NotificationDef>;
}

export class AttributesToLoad {
  attributes: Array<string> = [];
  resolves: Array<{ reference: string; attributes: Array<string> }> = [];
}

export class ImportAttributeDef {
  name: string;
  type?: string;
  isRequired?: boolean;
  isUnique?: boolean;
  validation?: string;
}

export class ImportItemDef {
  name: string;
  permission?: string[];
  objectType: string;
  separator?: string;
  width?: number;
  allowOverwrite?: boolean;
  attributes: Array<ImportAttributeDef>;
}

export class ImportItem {
  hasError: boolean;
  errorMsg?: string;
  uniquenessCheck?: string;
  objectToImport?: Resource;
}

export class BulkImportDef {
  permission?: string[];
  lineBreak: 'unix' | 'win';
  display: boolean;
  items: Array<ImportItemDef>;
}

export class SodException {
  creator: Resource;
  comment?: string;
  createdtime?: string;
}

export class SodConflict {
  conflictid: string;
  conflictname: string;
  conflictingobject1: Resource;
  conflictingobject2: Resource;
  conflictdescription?: string;
  exception?: SodException;
  previouscomment?: string;
  previoususer?: Resource;
  showresolver?: boolean;
  showexplorer?: boolean;
  explorerContent?: 'left' | 'right';
  decision?: 'approve' | 'reject' | 'escalate';
  decisioncreator?: Resource;
  decisiondate?: string;
  comment?: string;
  ishidden?: boolean;
  existNoMore?: boolean;
}

export class AttestationCheckElement {
  displayname: string;
  objectid: string;
  objecttype: string;
  isdirect?: boolean;
  decision?: 'approve' | 'reject' | 'escalate';
  comment?: string;
  ishidden?: boolean;
  showexplorer?: boolean;
}

export class AttestationItem {
  target: Resource;
  roles?: Array<AttestationCheckElement>;
  sods?: Array<SodConflict>;
  showdetail?: boolean;
}

export class AttestationInfo {
  items: Array<AttestationItem>;
  decision?: boolean;
  comment?: string;
  decisions?: Array<any>;
}
