import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnDestroy,
  ViewChild,
  AfterViewInit,
} from '@angular/core';

import { EMPTY, forkJoin, Observable, of, Subscription } from 'rxjs';
import {
  map,
  tap,
  switchMap,
  finalize,
  catchError,
  expand,
  reduce,
} from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { State } from '@progress/kendo-data-query';
import {
  GridDataResult,
  DataStateChangeEvent,
  GridComponent,
  RowArgs,
  CellClickEvent,
  SelectAllCheckboxState,
} from '@progress/kendo-angular-grid';
import { ClipboardService, IClipboardResponse } from 'ngx-clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TransService } from '../../models/translation.model';

import { DynamicComponent } from '../../models/dynamicComponent.interface';
import {
  ResourceTableConfig,
  ResourceColumnConfig,
  ModalType,
} from '../../models/componentContract.model';
import {
  ResourceSet,
  Resource,
  BroadcastEvent,
  AuthMode,
  ApiDef,
} from '../../models/dataContract.model';

import { ResourceService } from '../../services/resource.service';
import { UtilsService } from '../../services/utils.service';
import { SwapService } from '../../services/swap.service';

import { ResourceTableConfigComponent } from './resource-table-config.component';
import { AuthService } from '../../services/auth.service';
import { ConfigService } from '../../services/config.service';
import { ModalService } from '../../services/modal.service';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-resource-table',
  templateUrl: './resource-table.component.html',
  styleUrls: ['./resource-table.component.scss'],
})
export class ResourceTableComponent
  implements OnInit, AfterViewInit, OnDestroy, DynamicComponent
{
  private filterCondition: string;

  private subscription: Subscription = new Subscription();

  private pagingToken: string;
  private oldState: State;
  private maxSkip = 0;
  private cachedData: {
    data: any[];
    total: number;
    hasMoreItems?: boolean;
    continuationToken?: string;
  };

  private refreshTokenPath = 'resources/search/refreshtokens';

  private exportStepCount = 100;
  private exportLimit = 5000;
  private exportModal: MatDialogRef<ModalComponent, any>;
  private exportCanceled = false;

  private editableItems: Array<Resource> = null;

  @ViewChild('grid') kGrid: GridComponent;

  @Input()
  config: ResourceTableConfig;

  @Input()
  historyTimestamp: string;

  @Input()
  historyReportName: string;

  @Input()
  adjustIncommingData: (data: any) => ResourceSet;

  @Input()
  adjustApiData: (data: GridDataResult) => GridDataResult;

  @Input()
  adjustApiPageSize: (pageSize: number, apiDef: ApiDef) => ApiDef;

  @Input()
  adjustApiStateChange: (state: DataStateChangeEvent, apiDef: ApiDef) => ApiDef;

  @Input()
  adjustShowMasterDetail: (dataItem: any) => boolean;

  @Input()
  editable: boolean;

  @Input()
  enableDataExchange: boolean;

  @Input()
  enableRemoveAll: boolean;

  @Input()
  simpleMode: boolean;

  @Output()
  primaryAction = new EventEmitter<BroadcastEvent>();

  @Output()
  selectionChange = new EventEmitter();

  @Output()
  importData = new EventEmitter();

  @Output()
  exportData = new EventEmitter();

  @Output()
  removeAllData = new EventEmitter();

  get allColumns() {
    if (!this.localConfig) {
      return [];
    }
    return this.localConfig.columns.concat(
      this.localConfig.dynamicColumns ?? []
    );
  }

  get isCloud(): boolean {
    return this.auth.authMode === AuthMode.azure;
  }

  localConfig: ResourceTableConfig;

  hasData: boolean;

  errorMessage: string;

  gridState: State;
  gridResources: Observable<GridDataResult>;
  excelData: Observable<GridDataResult>;
  gridLoading = false;
  gridSelect: any;
  selection: string[] = [];
  selectedItems: Array<any> = [];
  clickedRowItem: Resource;

  selectAllState: SelectAllCheckboxState = 'unchecked';

  translationTrigger: number;

  virtualTotalItem: number;

  allowPageInfo = true;

  historicalReportBackColor = '';
  historicalReportFrontColor = '';

  noMoreItems = false;
  noMoreCachedItems = false;

  countBeforeEllipsis = 50;

  showRefreshButton = false;
  refreshButtonClicked = false;

  constructor(
    private dialog: MatDialog,
    private resource: ResourceService,
    private utils: UtilsService,
    private clipboard: ClipboardService,
    private snackbar: MatSnackBar,
    private translate: TransService,
    private swap: SwapService,
    private auth: AuthService,
    private configService: ConfigService,
    private modal: ModalService
  ) {}

  private expandRows() {
    if (this.kGrid && this.gridState) {
      const count = this.gridState.take + this.gridState.skip;
      if (this.localConfig.detailExpanded) {
        for (let index = 0; index < count; index++) {
          this.kGrid.expandRow(index);
        }
      }
    }
  }

  private setResolvedQueries() {
    this.localConfig.resolvedQuery = undefined;
    this.localConfig.resolvedEditableQuery = undefined;

    const regex = /\[#\w+(-\w+)?#?\]/g;

    if (this.localConfig.query) {
      const rsQuery = this.resource.resolveLoginID(this.localConfig.query);
      const match = regex.exec(rsQuery);
      if (!match || match.length === 0) {
        this.localConfig.resolvedQuery = rsQuery;
      }
    }
    if (this.localConfig.queryEditableItems) {
      const rsQuery = this.resource.resolveLoginID(
        this.localConfig.queryEditableItems
      );
      const match = regex.exec(rsQuery);
      if (!match || match.length === 0) {
        this.localConfig.resolvedEditableQuery = rsQuery;
      }
    }
  }

  private buildFetchObservable(skip?: number, take?: number, fullType = false) {
    if (this.localConfig.resolvedQuery) {
      let sortString: string[] = [];
      if (this.gridState) {
        if (this.gridState.sort) {
          sortString = this.gridState.sort
            .filter((element) => element.dir !== undefined)
            .map((item) => `${item.field}:${item.dir}`);
        }
        if (
          sortString.length === 0 &&
          this.localConfig.initSort &&
          this.localConfig.initSort.length > 0
        ) {
          sortString = this.localConfig.initSort
            .filter((element) => element && element.dir !== undefined)
            .map((item) => `${item.field}:${item.dir}`);
        }
        if (
          this.gridState.filter &&
          this.gridState.filter.filters &&
          this.gridState.filter.filters.length > 0
        ) {
          this.filterCondition = this.utils.FilterToXPath(
            this.gridState.filter,
            this.localConfig.columns
          );
        } else {
          this.filterCondition = '';
        }
      }

      let result: ResourceSet;

      const attributesToLoad =
        fullType &&
        this.localConfig.exportAttributes &&
        this.localConfig.exportAttributes.length > 0
          ? this.localConfig.exportAttributes
          : this.localConfig.columns
              .concat(this.localConfig.dynamicColumns ?? [])
              .map((c) => c.field);

      // using /{type}[{condition}][{condition}] format
      return this.resource
        .getResourceByQuery(
          this.filterCondition
            ? `${this.localConfig.resolvedQuery}[${this.filterCondition}]`
            : this.localConfig.resolvedQuery,
          attributesToLoad,
          take ? take : this.gridState ? this.gridState.take : undefined,
          skip ? skip : this.gridState ? this.gridState.skip : undefined,
          true,
          sortString
        )
        .pipe(
          switchMap((totalResult: ResourceSet) => {
            result = totalResult;

            const attributesForEditableQuery = ['ObjectID'];
            sortString.forEach((ss: string) => {
              const pos = ss.indexOf(':');
              if (pos > 0) {
                const attrName = ss.substring(0, pos);
                if (
                  attributesForEditableQuery.findIndex(
                    (a: string) => a.toLowerCase() === attrName.toLowerCase()
                  ) < 0
                ) {
                  attributesForEditableQuery.push(attrName);
                }
              }
            });

            if (this.localConfig.resolvedEditableQuery && !this.editableItems) {
              return this.resource.getResourceByQuery(
                this.filterCondition
                  ? `${this.localConfig.resolvedEditableQuery}[${this.filterCondition}]`
                  : this.localConfig.resolvedEditableQuery,
                attributesForEditableQuery,
                100000
              );
            }
            return of(null);
          }),
          switchMap((editableResult: ResourceSet) => {
            if (editableResult && editableResult.results) {
              this.editableItems = editableResult.results;
            }

            this.hasData =
              result &&
              (result.totalCount > 0 ||
                (result.results && result.results.length > 0));

            this.maxSkip = 0;

            if (result) {
              if (result.continuationToken) {
                if (result.continuationToken !== this.pagingToken) {
                  if (this.pagingToken) {
                    this.resource.removeRefreshToken(this.pagingToken);
                  }
                  this.pagingToken = result.continuationToken;
                  this.resource.registerRefreshToken({
                    name: this.refreshTokenPath,
                    path: this.refreshTokenPath,
                    token: this.pagingToken,
                  });
                }
              } else {
                if (this.pagingToken) {
                  this.resource.removeRefreshToken(this.pagingToken);
                }
              }
            }

            if (this.hasData) {
              if (this.editableItems) {
                if (this.editableItems.length > 0) {
                  result.results.forEach((item: Resource) => {
                    const itemId = this.utils.ExtraValue(item, 'ObjectID');
                    const foundIndex = this.editableItems.findIndex(
                      (r: Resource) =>
                        this.utils.ExtraValue(r, 'ObjectID') === itemId
                    );
                    item.editable = foundIndex >= 0;
                  });
                } else {
                  result.results.forEach((item: Resource) => {
                    item.editable = false;
                  });
                }
              }
            }

            if (this.auth.authMode === AuthMode.azure) {
              let total = result.totalCount;
              if (!total) {
                this.allowPageInfo = false;
                if (result.hasMoreItems) {
                  total = 1000000;
                } else {
                  total = result.results.length;
                }
              } else {
                this.allowPageInfo = true;
              }
              this.cachedData = fullType
                ? {
                    data: result.results,
                    total,
                    hasMoreItems: result.hasMoreItems,
                    continuationToken: result.continuationToken,
                  }
                : {
                    data: result.results,
                    total,
                  };
              return of(this.cachedData);
            } else {
              return fullType
                ? of({
                    data: result.results,
                    total: result.totalCount,
                    hasMoreItems: result.hasMoreItems,
                    continuationToken: result.continuationToken,
                  })
                : of({
                    data: result.results,
                    total: result.totalCount,
                  });
            }
          })
        );
    } else if (this.localConfig.api) {
      let apiConfig = this.utils.DeepCopy(this.localConfig.api);
      if (this.adjustApiStateChange) {
        apiConfig = this.adjustApiStateChange(
          this.gridState as DataStateChangeEvent,
          apiConfig
        );
      }
      if (this.adjustApiPageSize) {
        apiConfig = this.adjustApiPageSize(
          take
            ? take
            : this.gridState
            ? this.gridState.take
            : this.localConfig.pageSize,
          apiConfig
        );
      }
      return this.resource
        .callApi(
          apiConfig.method,
          apiConfig.path,
          apiConfig.param,
          apiConfig.body,
          apiConfig.header
        )
        .pipe(
          switchMap((result: ResourceSet) => {
            if (this.adjustIncommingData) {
              result = this.adjustIncommingData(result);
            }

            this.hasData =
              result &&
              (result.totalCount > 0 ||
                (result.results && result.results.length > 0));

            this.maxSkip = 0;

            if (
              result &&
              result.continuationToken &&
              result.continuationToken !== this.pagingToken
            ) {
              if (this.pagingToken) {
                this.resource.removeRefreshToken(this.pagingToken);
              }
              this.pagingToken = result.continuationToken;
              this.resource.registerRefreshToken({
                name: this.localConfig.api.pathRefresh,
                path: this.localConfig.api.pathRefresh,
                token: this.pagingToken,
              });
            }

            if (this.auth.authMode === AuthMode.azure) {
              if (
                result.totalCount === null ||
                result.totalCount === undefined
              ) {
                this.allowPageInfo = false;
              } else {
                this.allowPageInfo = true;
              }
              this.cachedData = {
                data: result.results,
                total: result.totalCount ?? 1000000,
              };

              if (result.hasMoreItems === false) {
                this.noMoreCachedItems = true;
                this.noMoreItems = true;
              } else {
                this.noMoreItems = false;
              }

              if (this.adjustApiData) {
                this.cachedData = this.adjustApiData(
                  this.cachedData as GridDataResult
                );
              }
              return of(this.cachedData as GridDataResult);
            } else {
              let resultData = {
                data: result.results,
                total: result.totalCount,
              };
              if (this.adjustApiData) {
                resultData = this.adjustApiData(resultData as GridDataResult);
              }
              return of(resultData as GridDataResult);
            }
          })
        );
    } else if (this.localConfig.resources) {
      let totalCount = this.localConfig.resources.length;
      return of(this.localConfig.resources).pipe(
        map((filterResources) => {
          if (
            this.gridState.filter &&
            this.gridState.filter.filters &&
            this.gridState.filter.filters.length > 0
          ) {
            const filterDef = this.gridState.filter.filters[0] as {
              filters: Array<{
                field: string;
                operator: string;
                value: string;
              }>;
              logic: string;
            };
            if (
              filterDef &&
              filterDef.filters &&
              filterDef.filters.length > 0
            ) {
              const filter = filterDef.filters[0];
              switch (filter.operator) {
                case 'contains':
                  return filterResources.filter((element) => {
                    return element[filter.field].indexOf(filter.value) >= 0;
                  });
                case 'startswith':
                  return filterResources.filter((element) => {
                    return element[filter.field].indexOf(filter.value) === 0;
                  });
                default:
                  break;
              }
            }
          }
          return filterResources;
        }),
        map((sortResources) => {
          totalCount = sortResources.length;
          if (this.gridState.sort && this.gridState.sort.length > 0) {
            const sortField = this.gridState.sort[0].field;
            const sortOrder = this.gridState.sort[0].dir;
            return sortResources.sort((a, b) => {
              if (sortOrder === 'asc') {
                if (!a[sortField]) {
                  return -1;
                }
                if (!b[sortField]) {
                  return 1;
                }
                if (typeof a[sortField] === 'string') {
                  if (a[sortField] > b[sortField]) {
                    return 1;
                  }
                  if (a[sortField] < b[sortField]) {
                    return -1;
                  }
                } else {
                  if (!a[sortField].DisplayName) {
                    return -1;
                  }
                  if (!b[sortField].DisplayName) {
                    return 1;
                  }
                  if (a[sortField].DisplayName > b[sortField].DisplayName) {
                    return 1;
                  }
                  if (a[sortField].DisplayName < b[sortField].DisplayName) {
                    return -1;
                  }
                }
              } else if (sortOrder === 'desc') {
                if (!a[sortField]) {
                  return 1;
                }
                if (!b[sortField]) {
                  return -1;
                }
                if (typeof a[sortField] === 'string') {
                  if (a[sortField] > b[sortField]) {
                    return -1;
                  }
                  if (a[sortField] < b[sortField]) {
                    return 1;
                  }
                } else {
                  if (!a[sortField].DisplayName) {
                    return 1;
                  }
                  if (!b[sortField].DisplayName) {
                    return -1;
                  }
                  if (a[sortField].DisplayName > b[sortField].DisplayName) {
                    return -1;
                  }
                  if (a[sortField].DisplayName < b[sortField].DisplayName) {
                    return 1;
                  }
                }
              }
              return 0;
            });
          } else {
            return sortResources;
          }
        }),
        map((resources) => {
          return resources.slice(
            skip ? skip : this.gridState ? this.gridState.skip : 0,
            take
              ? skip + take
              : this.gridState
              ? this.gridState.skip + this.gridState.take
              : 0
          );
        }),
        map((ro) => {
          return {
            data: ro,
            total: totalCount,
          } as GridDataResult;
        })
      );
    }
  }

  private fetchDataDic() {
    this.errorMessage = null;
    this.gridLoading = true;

    this.subscription.add(
      this.buildFetchObservable()
        .pipe(
          tap((result: GridDataResult) => {
            if (
              result &&
              result.total &&
              this.localConfig.scrollMode === 'virtual'
            ) {
              this.virtualTotalItem = result.total;
            }
            this.gridResources = of(result);
          }),
          catchError((error) => {
            if (error.error) {
              this.errorMessage = error.error;
            } else if (error.message) {
              this.errorMessage = error.message;
            } else {
              this.errorMessage = this.translate.instant('key_invalidXpath');
            }

            this.gridLoading = false;
            this.gridResources = of(null);
            return EMPTY;
          }),
          finalize(() => {
            this.gridLoading = false;
            this.expandRows();
          })
        )
        .subscribe()
    );
  }

  private fetchNextPage() {
    let pagingMode = '';
    if (this.oldState && this.gridState) {
      if (this.gridState.skip - this.oldState.skip > this.gridState.take) {
        this.gridState = this.oldState;
        return;
      }

      if (this.gridState.filter !== this.oldState.filter) {
        pagingMode = 'new';
      } else if (
        this.oldState &&
        this.oldState.sort &&
        this.gridState.sort !== this.oldState.sort
      ) {
        pagingMode = 'new';
      } else {
        if (this.gridState.skip > this.oldState.skip) {
          if (this.gridState.skip > this.maxSkip) {
            this.maxSkip = this.gridState.skip;
            pagingMode = 'next';
          } else {
            pagingMode = 'cache';
          }
        } else if (this.gridState.skip < this.oldState.skip) {
          pagingMode = 'cache';
        } else {
          pagingMode = 'new';
        }
      }

      switch (pagingMode) {
        case 'next':
          {
            this.gridLoading = true;
            this.subscription.add(
              this.resource
                .getNextPage(
                  this.pagingToken,
                  this.gridState.take,
                  this.localConfig.api && this.localConfig.api.pathContinue
                    ? this.localConfig.api.pathContinue
                    : null
                )
                .pipe(
                  tap((result: ResourceSet) => {
                    let resultData = {
                      data: result.results,
                      total: result.totalCount ?? 1000000,
                    };
                    if (this.adjustApiData) {
                      resultData = this.adjustApiData(
                        resultData as GridDataResult
                      );
                    }

                    if (this.editableItems) {
                      resultData.data.map((d: Resource) => {
                        const pos = this.editableItems.findIndex(
                          (e) =>
                            this.utils.ExtraValue(e, 'ObjectID') ===
                            this.utils.ExtraValue(d, 'ObjectID')
                        );
                        d.editable = pos >= 0;
                        return d;
                      });
                    }

                    this.cachedData.data = this.cachedData.data.concat(
                      resultData.data
                    );

                    if (result.hasMoreItems === false) {
                      this.noMoreItems = true;
                      this.noMoreCachedItems = true;
                    } else {
                      this.noMoreItems = false;
                    }

                    this.gridResources = of({
                      data: resultData.data,
                      total: this.cachedData.total,
                    } as GridDataResult);
                  }),
                  finalize(() => {
                    this.gridLoading = false;
                    this.expandRows();
                  }),
                  catchError(() => {
                    this.gridLoading = false;
                    return EMPTY;
                  })
                )
                .subscribe()
            );
          }
          break;
        case 'cache':
          if (
            this.gridState.skip + this.gridState.take >=
            this.cachedData.data.length
          ) {
            this.noMoreCachedItems = true;
          } else {
            this.noMoreCachedItems = false;
          }

          this.gridResources = of({
            data: this.cachedData.data.slice(
              this.gridState.skip,
              this.gridState.skip + this.gridState.take
            ),
            total: this.cachedData.total,
          });
          break;
        case 'new':
          this.maxSkip = 0;
          this.cachedData = undefined;
          this.gridState.skip = 0;
          this.noMoreItems = false;
          this.noMoreCachedItems = false;
          this.fetchDataDic();
          break;
        default:
          break;
      }
    }
  }

  private prepareForExport(result: Array<Resource>): string {
    if (result && result.length > 0) {
      let attributes: Array<string>;
      if (
        !this.localConfig.exportAttributes ||
        this.localConfig.exportAttributes.length === 0
      ) {
        attributes = this.localConfig.columns.map((c) => c.field);
      } else {
        attributes = this.localConfig.columns
          .map((c) => c.field)
          .filter((a) => {
            return (
              this.localConfig.exportAttributes.findIndex(
                (e) => e.toLowerCase() === a.toLowerCase()
              ) >= 0
            );
          });
      }

      let returnText = this.localConfig.exportHeader
        ? attributes.join(this.localConfig.exportSeparator) + '\n'
        : '';

      result.forEach((res) => {
        let line = '';
        attributes.forEach((attr) => {
          const attributeValue = this.utils.ExtraValue(res, attr);
          if (!attributeValue) {
            line += this.localConfig.exportSeparator;
          } else {
            line += `${attributeValue}${this.localConfig.exportSeparator}`;
          }
        });
        if (
          this.localConfig.exportState &&
          this.localConfig.exportState.enabled
        ) {
          line =
            (res.editable
              ? this.localConfig.exportState.stateUnlocked
              : this.localConfig.exportState.stateLocked) +
            this.localConfig.exportSeparator +
            line;
        }
        returnText += line.substring(0, line.length - 1) + '\n';
      });

      return returnText;
    } else {
      return '';
    }
  }

  private prepareForJsonExport(result: Array<Resource>): string {
    return JSON.stringify(result);
  }

  private buildDetailObs(result: GridDataResult): {
    [id: string]: Observable<ResourceSet>;
  } {
    const retVal: { [id: string]: Observable<ResourceSet> } = {};
    result.data.forEach((res: Resource) => {
      const refId = this.utils.ExtraValue(res, 'ObjectID');
      const refValue = this.utils.ExtraValue(
        res,
        `${this.localConfig.detailAttribute}:ObjectID`
      );
      if (refId && refValue) {
        const query = this.localConfig.detailQuery
          .replace(/%AttributeValue%/gi, refValue)
          .replace(
            /\[#LoginID\]/gi,
            this.utils.ExtraValue(this.resource.loginUser, 'ObjectID:value')
          );

        retVal[refId] = this.resource.getResourceByQuery(
          query,
          this.localConfig.detailTableConfig.columns.map((c) => c.field),
          this.exportLimit,
          0,
          true
        );
      }
    });

    return retVal;
  }

  private exportPagedData(): Observable<any> {
    this.exportCanceled = false;
    let exportedCount = 0;

    return this.buildFetchObservable(0, this.exportStepCount, true).pipe(
      expand((result: any) => {
        if (result && result.data && result.data.length > 0) {
          exportedCount += result.data.length;
          this.swap.broadcast({
            name: 'modal-title-update',
            parameter: [{ key: '__', value: exportedCount }],
          });
        }

        if (
          !this.exportCanceled &&
          result.hasMoreItems &&
          exportedCount < this.exportLimit
        ) {
          if (result.continuationToken) {
            return this.resource
              .getNextPage(result.continuationToken, this.exportStepCount)
              .pipe(
                map((resources: ResourceSet) => {
                  return {
                    data: resources.results,
                    hasMoreItems: resources.hasMoreItems,
                    total: resources.totalCount,
                    continuationToken: resources.continuationToken,
                  };
                })
              );
          } else {
            return this.buildFetchObservable(
              exportedCount,
              this.exportStepCount,
              true
            );
          }
        } else {
          return EMPTY;
        }
      }),
      reduce((acc, val) => {
        if (val.data && val.data.length > 0) {
          acc.data = [...acc.data, ...val.data];
        }
        return acc;
      }),
      finalize(() => {
        if (this.exportModal) {
          this.exportModal.close();
        }
      })
    );
  }

  private resolvePagedData(ob: Observable<any>): Observable<any> {
    return ob.pipe(
      map((rs) => {
        const cp = JSON.parse(JSON.stringify(rs));
        if (cp.data && cp.data.length > 0) {
          cp.data.forEach((item: any) => {
            Object.entries(item).forEach(([key, value]) => {
              if (value !== null && typeof value === 'object') {
                if (Array.isArray(value)) {
                  const v = value.reduce((a, b) => {
                    const s =
                      typeof a === 'string'
                        ? a
                        : this.localConfig.exportReferenceId
                        ? `${this.utils.ExtraValue(
                            a,
                            'DisplayName'
                          )}(${this.utils.ExtraValue(a, 'ObjectID')})`
                        : this.utils.ExtraValue(a, 'DisplayName');
                    const c =
                      typeof b === 'string'
                        ? b
                        : this.localConfig.exportReferenceId
                        ? `${this.utils.ExtraValue(
                            b,
                            'DisplayName'
                          )}(${this.utils.ExtraValue(b, 'ObjectID')})`
                        : this.utils.ExtraValue(a, 'DisplayName');
                    return `${s};${c}`;
                  });
                  item[key] =
                    typeof v === 'string'
                      ? v
                      : this.localConfig.exportReferenceId
                      ? `${this.utils.ExtraValue(
                          v,
                          'DisplayName'
                        )}(${this.utils.ExtraValue(v, 'ObjectID')})`
                      : this.utils.ExtraValue(v, 'DisplayName');
                } else {
                  if (this.localConfig.exportReferenceId) {
                    item[key] = `${this.utils.ExtraValue(
                      value,
                      'DisplayName'
                    )}(${this.utils.ExtraValue(value, 'ObjectID')})`;
                  } else {
                    item[key] = this.utils.ExtraValue(value, 'DisplayName');
                  }
                }
              }
            });
          });
        }
        return cp;
      })
    );
  }

  ngOnInit() {
    this.initComponent();
  }

  ngAfterViewInit(): void {
    console.log();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();

    if (this.pagingToken) {
      this.resource.removeRefreshToken(this.pagingToken);
    }
  }

  resize() {}

  clear() {
    this.localConfig = new ResourceTableConfig();
    this.hasData = false;
    this.gridResources = of({
      data: [],
      total: 0,
    } as GridDataResult);
    this.gridLoading = false;
  }

  initComponent() {
    this.historicalReportBackColor = this.configService.getConfig(
      'historicalReportBackColor',
      'lightgoldenrodyellow'
    );
    this.historicalReportFrontColor = this.configService.getConfig(
      'historicalReportFrontColor',
      'black'
    );

    this.countBeforeEllipsis = this.configService.getConfig(
      'countBeforeEllipsis',
      50
    );

    if (
      this.simpleMode &&
      !this.configService.getConfigEx(
        'advancedViewSettings:loadMultivaluedReferences',
        true
      )
    ) {
      this.showRefreshButton = true;
    }

    this.localConfig = new ResourceTableConfig();

    this.utils.CopyInto(
      this.utils.DeepCopy(this.config),
      this.localConfig,
      true,
      true
    );

    this.setResolvedQueries();

    this.subscription.add(
      this.clipboard.copyResponse$.subscribe((res: IClipboardResponse) => {
        if (res.isSuccess) {
          this.snackbar.open(this.translate.instant('key_textCopied'), 'OK', {
            duration: 2000,
          });
        }
      })
    );

    this.subscription.add(
      this.swap.broadcasted.subscribe((event: BroadcastEvent) => {
        if (event) {
          switch (event.name) {
            case 'refresh-language':
              setTimeout(() => {
                this.translationTrigger = Math.random() * 10;
              });
              // this.localConfig.columns.forEach(c => {
              //   const tempTitle = c.title;
              //   c.title = '...';
              //   setTimeout(() => {
              //     c.title = tempTitle;
              //   });
              // });
              break;
            default:
              break;
          }
        }
      })
    );

    this.gridState = {
      take: this.localConfig.pageSize,
      skip: 0,
    };

    this.gridSelect =
      this.localConfig.selectable && this.editable !== false
        ? {
            checkboxOnly: this.localConfig.checkboxSelectOnly,
            mode: this.localConfig.selectMode,
          }
        : false;

    if (this.localConfig.applyQueryOnLoad) {
      this.fetchDataDic();
    }

    this.exportStepCount = this.configService.getConfig('exportStepCount', 100);
    this.exportLimit =
      this.localConfig.exportMaxCount ||
      this.configService.getConfig('maxExportCount', 5000);

    return this.localConfig;
  }

  configure() {
    const configCopy = this.utils.DeepCopy(this.localConfig);

    const dialogRef = this.dialog.open(ResourceTableConfigComponent, {
      minWidth: '600px',
      data: {
        component: this,
        config: this.localConfig,
      },
    });

    return dialogRef.afterClosed().pipe(
      tap((result) => {
        if (!result || (result && result === 'cancel')) {
          this.localConfig = configCopy;
        }
        this.updateDataSource(false, true);
      }),
      switchMap(() => {
        const returnValue: ResourceTableConfig = this.utils.DeepCopy(
          this.localConfig
        );
        if (returnValue.resolvedQuery || returnValue.resolvedQuery === '') {
          delete returnValue.resolvedQuery;
        }
        if (
          returnValue.resolvedEditableQuery ||
          returnValue.resolvedEditableQuery === ''
        ) {
          delete returnValue.resolvedEditableQuery;
        }
        return of(returnValue);
      })
    );
  }

  updateDataSource(
    applyConfig = false,
    recalculate = false,
    keepState = false
  ) {
    if (!keepState) {
      this.gridState = { take: this.localConfig.pageSize, skip: 0 };
      if (this.kGrid) {
        this.kGrid.sort = [];
      }
    }

    this.gridSelect =
      this.localConfig.selectable && this.editable !== false
        ? {
            checkboxOnly: this.localConfig.checkboxSelectOnly,
            mode: this.localConfig.selectMode,
          }
        : false;

    this.selection = [];

    if (applyConfig) {
      this.utils.CopyInto(
        this.utils.DeepCopy(this.config),
        this.localConfig,
        true
      );
      this.localConfig.resolvedQuery = this.resource.resolveLoginID(
        this.localConfig.query
      );
      this.localConfig.resolvedEditableQuery = this.resource.resolveLoginID(
        this.localConfig.queryEditableItems
      );
    } else if (recalculate) {
      this.localConfig.resolvedQuery = this.resource.resolveLoginID(
        this.localConfig.query
      );
      this.localConfig.resolvedEditableQuery = this.resource.resolveLoginID(
        this.localConfig.queryEditableItems
      );
    }

    this.fetchDataDic();
  }

  getStatusSetting(data: any, col: ResourceColumnConfig) {
    if (!data) {
      return col.fallbackStatus;
    }

    const itemValue = this.utils.ExtraValue(data, {
      value: col.field + ':value',
      isHistorical: col.reportType === 'historical',
    });

    if (itemValue !== null && itemValue !== undefined) {
      let propertyName = '';
      for (const name of Object.getOwnPropertyNames(col.showStatus)) {
        if (name.toLowerCase() === String(itemValue).toLowerCase()) {
          propertyName = name;
          break;
        }
      }
      if (propertyName) {
        return col.showStatus[propertyName];
      } else {
        return col.fallbackStatus ? col.fallbackStatus : { color: 'goldenrod' };
      }
    } else {
      return col.fallbackStatus ? col.fallbackStatus : { color: 'goldenrod' };
    }

    // if (
    //   itemValue === null ||
    //   itemValue === undefined ||
    //   !col.showStatus[String(itemValue).toLowerCase()]
    // ) {
    //   return col.fallbackStatus ? col.fallbackStatus : { color: 'goldenrod' };
    // }

    // return col.showStatus[String(itemValue).toLowerCase()];
  }

  resetSelection() {
    this.selection = [];
    this.selectAllState = 'unchecked';
  }

  showOperator(col: ResourceColumnConfig, name: string): boolean {
    if (col && col.filterOperators && col.filterOperators.indexOf(name) < 0) {
      return false;
    }

    return true;
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.oldState = this.gridState;
    this.gridState = state;

    if (this.adjustApiStateChange) {
      this.localConfig.api = this.adjustApiStateChange(
        state,
        this.localConfig.api
      );
    }

    if (
      (!this.localConfig.resources ||
        this.localConfig.resources.length === 0) &&
      this.auth.authMode === AuthMode.azure
    ) {
      this.fetchNextPage();
    } else {
      this.fetchDataDic();
    }
  }

  showMasterDetail = (dataItem: any): boolean => {
    if (
      this.localConfig &&
      (this.localConfig.detailType === 'table' ||
        this.localConfig.detailType === 'component')
    ) {
      if (this.adjustShowMasterDetail) {
        return this.adjustShowMasterDetail(dataItem);
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  dataItemIsArray(item: any, attrPath: any): boolean {
    return Array.isArray(this.utils.ExtraValue(item, attrPath));
  }

  dataItemHasMorePages(item: any, attrPath: any): boolean {
    const arr: Array<any> = this.utils.ExtraValue(item, attrPath);
    if (!arr) {
      return false;
    }
    return arr.length > this.configService.getConfig('pageSize', 50);
  }

  dataValueIsReference(item: any, attrPath: any): boolean {
    if (
      attrPath &&
      typeof attrPath.value === 'string' &&
      attrPath.value.toLowerCase().indexOf(':objectid') >= 0
    ) {
      return false;
    }
    const itemValue = this.utils.ExtraValue(item, attrPath);
    if (itemValue) {
      return this.utils.ExamValue(itemValue, 'DisplayName');
    }
    return false;
  }

  dataValueIsGuid(item: any, attrPath: any) {
    if (
      attrPath &&
      typeof attrPath.value === 'string' &&
      attrPath.value.toLowerCase().indexOf(':objectid') >= 0
    ) {
      return false;
    }
    return this.utils.IsGuidAttribute(item, attrPath);
  }

  isGuid(text: string) {
    return this.utils.IsGuid(text);
  }

  allData = (): Observable<any> => {
    if (this.localConfig.exportAllPages) {
      const msg = `${this.translate.instant(
        'key_export'
      )} __ ${this.translate.instant('key_resources')}`;
      this.exportModal = this.modal.show(ModalType.progress, msg, '', '', [
        { text: 'key_cancel', value: 'cancel' },
      ]);

      this.subscription.add(
        this.exportModal
          .afterClosed()
          .pipe(
            tap((result) => {
              if (result === 'cancel') {
                this.exportCanceled = true;
              }
            })
          )
          .subscribe()
      );

      return this.resolvePagedData(this.exportPagedData());
    } else {
      this.gridLoading = true;
      return this.resolvePagedData(this.gridResources).pipe(
        finalize(() => {
          this.gridLoading = false;
        })
      );
    }
  };

  getTooltip(col: ResourceColumnConfig, item: any) {
    const value = this.utils.ExtraValue(item, {
      value: col.field + ':value',
      format: this.localConfig.datetimeFormat,
      isHistorical: col.reportType === 'historical',
    });
    if (
      value &&
      this.countBeforeEllipsis > 0 &&
      value.length > this.countBeforeEllipsis
    ) {
      return value;
    } else if (col.tooltipField) {
      return this.utils.ExtraValue(item, col.tooltipField + ':value');
    }

    return null;
  }

  onExcelExport(grid: GridComponent) {
    grid.saveAsExcel();
  }

  getSelectionAttribute(context: RowArgs): string {
    if (context.dataItem) {
      if (Object.keys(context.dataItem).indexOf('ObjectID') >= 0) {
        return context.dataItem.ObjectID;
      } else if (Object.keys(context.dataItem).indexOf('objectid') >= 0) {
        return context.dataItem.objectid;
      }
    }

    return undefined;
  }

  onExportToClipboard() {
    if (this.localConfig.exportAllPages) {
      const msg = `${this.translate.instant(
        'key_export'
      )} __ ${this.translate.instant('key_resources')}`;
      this.exportModal = this.modal.show(ModalType.progress, msg, '', '', [
        { text: 'key_cancel', value: 'cancel' },
      ]);

      this.subscription.add(
        this.exportModal
          .afterClosed()
          .pipe(
            tap((result) => {
              if (result === 'cancel') {
                this.exportCanceled = true;
              }
            })
          )
          .subscribe()
      );

      this.subscription.add(
        this.resolvePagedData(this.exportPagedData())
          .pipe(
            tap((result) => {
              if (result && result.data) {
                const textToCopy = this.prepareForExport(result.data);
                this.clipboard.copy(textToCopy);
              }
            })
          )
          .subscribe()
      );
    } else {
      this.gridLoading = true;
      this.subscription.add(
        this.resolvePagedData(this.gridResources)
          .pipe(
            tap((result: GridDataResult) => {
              const textToCopy = this.prepareForExport(result.data);
              this.clipboard.copy(textToCopy);
            }),
            catchError(() => {
              this.gridLoading = false;
              return EMPTY;
            }),
            finalize(() => {
              this.gridLoading = false;
            })
          )
          .subscribe()
      );
    }
    return;
  }

  onCellClick(event: CellClickEvent) {
    this.clickedRowItem = event.dataItem;
  }

  onDoubleClick() {
    this.primaryAction.emit({
      name: 'ResourceTableComponent',
      parameter: {
        resource: this.clickedRowItem,
        navigationKey: this.localConfig.navigationKey,
      },
    });
  }

  onSelectAllChange(checkedState: SelectAllCheckboxState) {
    if (checkedState === 'checked') {
      this.selectAllState = 'checked';
    } else {
      this.selection = [];
      this.selectAllState = 'unchecked';
    }
  }

  // onSelectedKeysChange() {
  //   const len = this.selection.length;
  //   if (len === 0) {
  //     this.selectAllState = 'unchecked';
  //   } else if (len > 0 && len < this.localConfig.pageSize) {
  //     this.selectAllState = 'indeterminate';
  //   } else {
  //     this.selectAllState = 'checked';
  //   }

  //   this.selectionChange.emit();
  // }

  onSelectionChange(selectionChange: any) {
    if (selectionChange) {
      selectionChange.selectedRows.forEach((item: any) => {
        if (item.dataItem && item.dataItem.editable !== false) {
          const id = this.utils.ExtraValue(item.dataItem, 'ObjectID');
          if (
            this.selectedItems.findIndex((element) => element.ObjectID === id) <
            0
          ) {
            this.selectedItems.push({
              DisplayName: this.utils.ExtraValue(item.dataItem, 'DisplayName'),
              ObjectID: id,
              ObjectType: this.utils.ExtraValue(item.dataItem, 'ObjectType'),
            });
          }
        }
      });
      selectionChange.deselectedRows.forEach((item: any) => {
        if (item.dataItem) {
          const id = this.utils.ExtraValue(item.dataItem, 'ObjectID');
          if (id) {
            const index = this.selectedItems.findIndex(
              (element) => element.ObjectID === id
            );
            if (index >= 0) {
              this.selectedItems.splice(index, 1);
            }
          }
        }
      });
    }

    if (selectionChange && selectionChange.selectedRows) {
      const disabledItems: any[] = selectionChange.selectedRows
        .filter((row: any) => {
          return row.dataItem && row.dataItem.editable === false;
        })
        .map((row: any) => row.dataItem);

      disabledItems.forEach((item: any) => {
        const id = this.utils.ExtraValue(item, 'ObjectID:valeu');
        if (id) {
          const pos = this.selection.indexOf(id);
          if (pos >= 0) {
            this.selection.splice(pos, 1);
          }
        }
      });

      if (this.selection.length === 0) {
        this.selectAllState = 'unchecked';
      }

      // const len = this.selection.length;
      // if (len === 0) {
      //   this.selectAllState = 'unchecked';
      // } else if (len > 0 && len < this.localConfig.pageSize) {
      //   this.selectAllState = 'indeterminate';
      // } else {
      //   this.selectAllState = 'checked';
      // }

      this.selectionChange.emit(this.selection);
    } else {
      this.selectionChange.emit([]);
    }
  }

  onImportData() {
    this.importData.emit();
  }

  onExportData() {
    this.exportData.emit();
  }

  onRemoveAllData() {
    this.removeAllData.emit();
  }

  onLoadData() {
    this.refreshButtonClicked = true;
    this.updateDataSource();
  }
}
