import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';

import { RouteGuardService } from './core/services/route-guard.service';

import { TestComponent } from './test/test.component';
import { LoginComponent } from './login/login.component';
import { SplashComponent } from './splash/splash.component';
import { HomeComponent } from './home/home.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ResourcesComponent } from './resources/resources.component';
import { AllResourcesComponent } from './all-resources/all-resources.component';
import { SettingsComponent } from './settings/settings.component';
import { OrgStructureComponent } from './org-structure/org-structure.component';

import { ResourceListComponent } from './resource-list/resource-list.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { AdvancedSearchComponent } from './advanced-search/advanced-search.component';
import { TimeMachineComponent } from './time-machine/time-machine.component';
import { EventComponent } from './event/event.component';
import { EventListComponent } from './event-list/event-list.component';
import { WizardSettingComponent } from './wizard-setting/wizard-setting.component';
import { ViewSettingComponent } from './view-setting/view-setting.component';
import { ConfigConverterComponent } from './config-converter/config-converter.component';
import { FormViewComponent } from './form-view/form-view.component';
import { ApprovalsComponent } from './approvals/approvals.component';
import { ReportsComponent } from './reports/reports.component';
import { ReportComponent } from './report/report.component';
import { ExpiredPageComponent } from './expired-page/expired-page.component';
import { MaintenancePageComponent } from './maintenance-page/maintenance-page.component';
import { InpageErrorComponent } from './inpage-error/inpage-error.component';
import { HistoricalStatusComponent } from './historical-status/historical-status.component';
import { ManualTasksComponent } from './manual-tasks/manual-tasks.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent, children: [] },
  { path: 'splash', component: SplashComponent, children: [] },
  { path: 'error', component: ErrorPageComponent, children: [] },
  { path: 'expired', component: ExpiredPageComponent, children: [] },
  { path: 'maintenance', component: MaintenancePageComponent, children: [] },
  {
    path: 'test',
    component: TestComponent,
    canActivate: [RouteGuardService],
    children: [],
  },
  {
    path: 'app',
    component: HomeComponent,
    canActivate: [RouteGuardService],
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      { path: 'home', component: DashboardComponent },
      {
        path: 'custom',
        loadChildren: () =>
          import('./customisation/custom.module').then((m) => m.CustomModule),
      },
      { path: 'error', component: InpageErrorComponent },
      { path: 'resources', component: ResourcesComponent },
      { path: 'allresources', component: AllResourcesComponent },
      { path: 'settings', component: SettingsComponent },
      { path: 'reports', component: ReportsComponent },
      { path: 'report/:name', component: ReportComponent },
      { path: 'approvals', component: ApprovalsComponent },
      { path: 'manualtasks', component: ManualTasksComponent },
      { path: 'wizardsetting/:type', component: WizardSettingComponent },
      { path: 'viewsetting', component: ViewSettingComponent },
      { path: 'configconverter', component: ConfigConverterComponent },
      { path: 'search', component: AdvancedSearchComponent },
      { path: 'timemachine', component: TimeMachineComponent },
      {
        path: 'historicalstatus/:type/:id/:timestamp/:report',
        component: HistoricalStatusComponent,
        outlet: 'sidenav',
      },
      {
        path: 'eventlist',
        component: EventListComponent,
      },
      {
        path: 'event/:id',
        component: EventComponent,
      },
      {
        path: 'event/:id',
        component: EventComponent,
        outlet: 'sidenav',
      },
      {
        path: 'orgstructure/:index',
        component: OrgStructureComponent,
        children: [
          {
            path: 'form/:type/:id',
            component: FormViewComponent,
          },
        ],
      },
      {
        path: 'resourcelist/:type',
        component: ResourceListComponent,
        children: [
          {
            path: 'form/:type/:id',
            component: FormViewComponent,
          },
        ],
      },
      {
        path: 'resourcelist/:type/:index',
        component: ResourceListComponent,
        children: [
          {
            path: 'form/:type/:id',
            component: FormViewComponent,
          },
        ],
      },
      {
        path: 'form/:type/:id',
        component: FormViewComponent,
        canDeactivate: [RouteGuardService],
      },
      {
        path: 'form/:type/:id',
        component: FormViewComponent,
        outlet: 'sidenav',
        canDeactivate: [RouteGuardService],
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(private router: Router) {
    this.router.events.subscribe();
  }
}
