import { Type } from '@angular/core';
import { SeriesType } from '@progress/kendo-angular-charts';
import { FilterableSettings } from '@progress/kendo-angular-grid';
import { PagerType } from '@progress/kendo-angular-grid/dist/es2015/pager/pager-settings';
import { ApiDef, AttributeStyle } from './dataContract.model';
import { ComponentConfig } from './dynamicComponent.interface';
import { CustomComponent } from './dynamicEditor.interface';

// #region modal

export enum ModalType {
  info = 'info',
  confirm = 'confirm',
  error = 'error',
  progress = 'progress',
}

export interface ModalData {
  type: ModalType;
  title: string;
  content: string;
  buttons?: Array<ModalButton>;
}

export class ModalButton {
  text?: string;
  value?: string;
  focus?: boolean;
  primary?: boolean;
}

// #endregion

// #region component definition

export class ComponentDef {
  id: string;
  name: string;
  type: string;
  icon?: string;
  width?: number;
  height?: number;
  description?: string;
  instance: any;
  instanceName: string;
}

export class ComponentItem {
  constructor(public component: Type<CustomComponent>, public data: any) {}
}

// #endregion

// #region resource-chart

export class ChartQueryConfig {
  name = '';
  method = 'counter';
  attribute = '';
  query = '';
  display?: boolean = undefined;
}

export class ChartSerieConfig {
  name = '';
  categoryField = 'category';
  valueField = 'value';
  color: string = undefined;
  data?: any;
  queryConfig?: ChartQueryConfig[];
  chartData?: any;
}

export class ResourceChartConfig implements ComponentConfig {
  name = 'resource-chart';
  permissionSets = undefined;
  chartType: SeriesType = 'pie';
  chartTitle = '';
  seriesColor = [
    '#3f51b5',
    '#2196f3',
    '#43a047',
    '#ffc107',
    '#ff5722',
    '#e91E63',
  ];
  enableLegend = false;
  legendPosition: 'top' | 'bottom' | 'left' | 'right' | 'custom' = 'bottom';
  enableTooltip = false;
  tooltipFormat = '{0}: {1}';
  enableLabel = false;
  labelFormat = '{1}';
  labelColor = 'black';
  seriesConfig: ChartSerieConfig[] = [];

  public constructor(init?: Partial<ResourceChartConfig>) {
    Object.assign(this, init);
  }
}

// #endregion

// #region resource-table

export class ResourceColumnConfig {
  field: string = undefined;
  tooltipField?: string = undefined;
  title?: string = undefined;
  isReference?: boolean = false;
  width?: number = undefined;
  verticalAlign?: 'top' | 'middle' | 'bottom' = 'middle';
  sortable?: boolean = undefined;
  filterable?: boolean = undefined;
  filter?: 'boolean' | 'numeric' | 'text' | 'date' = undefined;
  filterOperators?: Array<string> = undefined;
  locked?: boolean = undefined;
  display?: boolean = undefined;
  navigationKey?: string = undefined;
  linkNoneForm? = false;
  showStatus?: { [key: string]: { text?: string; color?: string } } = undefined;
  fallbackStatus?: { text?: string; color?: string } = {
    text: '',
    color: 'goldenrod',
  };
  reportType?: 'current' | 'recent' | 'historical' = undefined;
}

export class ResourceTableConfig implements ComponentConfig {
  name?: string;
  permissionSets?: string[];
  objectType?: string;
  navigationKey?: string;
  title?: string;
  fontSize?: number;
  fontWeight?: number;
  cellPadding?: number;
  pageSize?: number;
  pageCountNumber?: number;
  pageInfo?: boolean;
  pageType?: PagerType;
  pageButton?: boolean;
  scrollMode?: string;
  scrollHeight?: number;
  sortable?: boolean;
  sortMode?: 'single' | 'multiple';
  initSort?: Array<{ field: string; dir: string }>;
  allowUnsort?: boolean;
  filterable?: boolean;
  filterMode?: FilterableSettings;
  selectable?: boolean;
  selectBoxWidth?: number;
  selectMode?: string;
  selectAllEnabled?: boolean;
  checkboxSelectOnly?: boolean;
  resizable?: boolean;
  cellNoWrap?: boolean;
  defaultLinkAction?: string;
  linkActions?: Array<string>;
  linkNoneForm?: boolean;
  linkDisabled?: boolean;
  linkScope?: string;
  datetimeFormat?: string;
  exportToPDF?: boolean;
  exportToExcel?: boolean;
  exportToClipBoard?: boolean;
  exportAllPages?: boolean;
  exportHeader?: boolean;
  exportSeparator?: string;
  exportAttributes?: Array<string>;
  exportReferenceId?: boolean;
  exportState?: {
    enabled: boolean;
    stateLocked: string;
    stateUnlocked: string;
  };
  exportMaxCount?: number;
  resources?: any[];
  query?: string;
  detailType?: string; //none, table or component
  detailComponentID?: string;
  detailComponentData?: string;
  detailExpanded?: boolean;
  detailAttribute?: string;
  detailQuery?: string;
  detailTableConfig?: ResourceTableConfig;
  queryEditableItems?: string;
  resolvedQuery?: string;
  resolvedEditableQuery?: string;
  lockIcon?: string;
  lockColor?: string;
  lockText?: string;
  api?: ApiDef;
  applyQueryOnLoad?: boolean;
  tableHeight?: number;
  columns?: ResourceColumnConfig[];
  dynamicColumns?: ResourceColumnConfig[];

  public constructor() {
    this.name = undefined;
    this.permissionSets = undefined;
    this.objectType = 'Person';
    this.navigationKey = undefined;
    this.title = '';
    this.fontSize = 14;
    this.fontWeight = 300;
    this.pageSize = 10;
    this.cellPadding = 10;
    this.pageCountNumber = 5;
    this.pageInfo = true;
    this.pageType = 'numeric';
    this.pageButton = true;
    this.scrollMode = 'basic';
    this.scrollHeight = 36;
    this.sortMode = 'single';
    this.initSort = [];
    this.allowUnsort = true;
    this.filterMode = 'menu';
    this.selectable = false;
    this.selectBoxWidth = 45;
    this.selectMode = 'single';
    this.selectAllEnabled = true;
    this.checkboxSelectOnly = false;
    this.resizable = false;
    this.cellNoWrap = false;
    this.defaultLinkAction = '';
    this.linkActions = ['sideView', 'navigate'];
    this.linkNoneForm = false;
    this.linkDisabled = false;
    this.linkScope = '';
    this.datetimeFormat = '';
    this.exportToPDF = false;
    this.exportToExcel = true;
    this.exportToClipBoard = true;
    this.exportAllPages = false;
    this.exportHeader = true;
    this.exportSeparator = '|';
    this.exportAttributes = [];
    this.exportReferenceId = false;
    this.exportState = {
      enabled: false,
      stateLocked: 'locked',
      stateUnlocked: 'editable',
    };
    this.exportMaxCount = 0;
    this.resources = [];
    this.query = undefined;
    this.detailType = 'none';
    this.detailComponentID = '';
    this.detailComponentData = '';
    this.detailExpanded = false;
    this.detailAttribute = undefined;
    this.detailQuery = undefined;
    this.detailTableConfig = null;
    this.queryEditableItems = undefined;
    this.resolvedQuery = undefined;
    this.resolvedEditableQuery = undefined;
    this.lockIcon = 'lock';
    this.lockColor = 'disabled';
    this.lockText = 'key_locked';
    this.api = undefined;
    this.applyQueryOnLoad = true;
    this.tableHeight = undefined;
    this.columns = [
      {
        field: 'DisplayName',
        width: null,
        filterable: false,
        filter: 'text',
        sortable: true,
        locked: false,
      },
    ];
    this.dynamicColumns = [];
  }
}

export class TreeListConfig implements ComponentConfig {
  name: string;
  permissionSets: string[];
  objectType?: string;
  navigationKey?: string;
  title?: string;
  isExpanded?: boolean;
  fontSize?: number;
  fontWeight?: number;
  cellPadding?: number;
  pageSize?: number;
  pageCountNumber?: number;
  pageInfo?: boolean;
  pageType?: PagerType;
  pageButton?: boolean;
  scrollMode?: string;
  scrollHeight?: number;
  defaultLinkAction?: string;
  linkActions?: Array<string>;
  linkNoneForm?: boolean;
  linkReadOnly?: boolean;
  datetimeFormat?: string;
  listHeight?: number;
  columns?: ResourceColumnConfig[];
  dynamicColumns?: ResourceColumnConfig[];
  apiRootResources?: ApiDef;
  apiChildrenResources?: ApiDef;
  apiChildrenQueryPath?: string;
  idName?: string;
  childCounterName?: string;

  public constructor() {
    this.name = undefined;
    this.permissionSets = undefined;
    this.objectType = undefined;
    this.navigationKey = undefined;
    this.title = '';
    this.isExpanded = false;
    this.fontSize = 14;
    this.fontWeight = 300;
    this.pageSize = 10;
    this.cellPadding = 10;
    this.pageCountNumber = 5;
    this.pageInfo = true;
    this.pageType = 'numeric';
    this.pageButton = true;
    this.scrollMode = 'basic';
    this.scrollHeight = 36;
    this.defaultLinkAction = '';
    this.linkActions = ['sideView', 'navigate'];
    this.linkNoneForm = false;
    this.linkReadOnly = false;
    this.datetimeFormat = '';
    this.listHeight = undefined;
    this.columns = [
      {
        field: 'DisplayName',
        width: null,
      },
    ];
    this.dynamicColumns = [];
    this.apiRootResources = null;
    this.apiChildrenResources = null;
    this.apiChildrenQueryPath = null;
    this.idName = 'ObjectID';
    this.childCounterName = 'childcount';
  }
}

// #endregion

// #region state-card

export class StateCardConfig implements ComponentConfig {
  name = 'state-card';
  permissionSets = undefined;
  iconText: string = undefined;
  iconColor: string = undefined;
  backgroundColor: string = undefined;
  textColor: string = undefined;
  mainTextColor: string = undefined;
  title: string = undefined;
  mainText: string = undefined;
  useThemeColor = false;
  queryMode: string = undefined;
  queryAttribute: string = undefined;
  query: string = undefined;
  action: string = undefined;

  public constructor(init?: Partial<StateCardConfig>) {
    Object.assign(this, init);
  }
}

export class HtmlCardEntry {
  name?: string;
  html?: string;

  public constructor() {
    this.name = '';
    this.html = '';
  }
}

export class HtmlCardConfig implements ComponentConfig {
  name = 'html-card';
  permissionSets = undefined;
  showAsModal = false;
  entries: Array<HtmlCardEntry> = [];

  public constructor(init?: Partial<HtmlCardConfig>) {
    Object.assign(this, init);
  }
}

// #endregion

// #region action-card

export class ActionCardConfig implements ComponentConfig {
  name = 'action-card';
  permissionSets = undefined;
  actionSets: string[] = undefined;
  enabled = true;
  height?: number = undefined;
  width?: number = undefined;
  primaryIcon?: string = undefined;
  primaryIconColor?: string = undefined;
  primaryImage?: string = undefined;
  secondaryIcon?: string = undefined;
  secondaryIconColor?: string = undefined;
  backgroundColor?: string = undefined;
  title?: string = undefined;
  titleColor?: string = undefined;
  type?: string = undefined;
  description?: string = undefined;
  descriptionColor?: string = undefined;
  primaryAction?: string = undefined;
  secondaryAction?: string = undefined;
  textWidth?: number = undefined;

  public constructor(init?: Partial<ActionCardConfig>) {
    Object.assign(this, init);
  }
}

// #endregion

// #region report-card

export class ReportCardConfig implements ComponentConfig {
  name = '';
  type = 'report';
  permissionSets: string[] = undefined;
  icon = 'description';
  image: string = undefined;
  text = 'l10n_reports';
  description = 'l10n_reports';
  fontSize = 20;
  iconColor: string = undefined;
  textColor: string = undefined;
  descriptionColor: string = undefined;
  backgroundColor: string = undefined;
  query = '';
  tableConfig = new ResourceTableConfig();
  hideConfig = true;
  isHistorical = false;
  linkedObjectType = undefined;
  isRelativeDate = false;
  absoluteDate: string = undefined;
  relativeDate = 0;
  parameterDef = null;

  public constructor(init?: Partial<ReportCardConfig>) {
    Object.assign(this, init);
    this.tableConfig.resizable = true;
    this.tableConfig.pageSize = 20;
    this.tableConfig.detailTableConfig = new ResourceTableConfig();
    this.tableConfig.detailTableConfig.fontSize = 12;
    this.tableConfig.detailTableConfig.cellPadding = 5;
  }
}

// #endregion

// #region report-separator

export class ReportSeparatorConfig implements ComponentConfig {
  name = '';
  type = 'separator';
  permissionSets: string[] = undefined;
  icon = 'description';
  text = 'key_separator';
  description = 'key_separator';
  fontSize = 20;
  iconColor: string = undefined;
  textColor: string = undefined;
  descriptionColor: string = undefined;
  backgroundColor: string = undefined;
  showDivider = true;

  public constructor(init?: Partial<ReportSeparatorConfig>) {
    Object.assign(this, init);
  }
}

// #endregion

// #region tree-view

export class TreeViewConfig implements ComponentConfig {
  name?: string;
  objectType?: string;
  permissionSets?: string[];
  initQuery?: string;
  childrenQuery?: string;
  customFilter?: string;
  partialTree?: boolean;
  attributeStyles?: Array<AttributeStyle>;
  pageSize?: number;
  sort?: string[];

  public constructor() {
    this.name = undefined;
    this.objectType = 'ocgOrgUnit';
    this.permissionSets = undefined;
    this.initQuery = undefined;
    this.childrenQuery = undefined;
    this.customFilter = '';
    this.partialTree = false;
    this.attributeStyles = [];
    this.pageSize = 20;
    this.sort = [];
  }
}

// #endregion

// #region approval-view

export class ApprovalViewConfig implements ComponentConfig {
  name = 'approval-view';
  mode = 'onprem';
  permissionSets = undefined;
  title = 'key_approvalCheck';
  queryPendingApprovals = `/Approval[ApprovalStatus='Pending' and Approver='[#LoginID]' and not(ApprovalResponse=/ApprovalResponse[Creator='[#LoginID]'])]/Request`;
  queryAllApprovals = `/Request[ComputedActor='[#LoginID]' and ObjectID=/Approval/Request]`;
  showPendingApprovalsOnly = true;
  titleAttribute = '';
  contentAttribute = '';
  sortAttribute = 'CreatedTime';
  sortOrder = 'desc';
  limit = 20;
  canApprove = true;
  defaultContent = 'detail';

  public constructor(init?: Partial<ApprovalViewConfig>) {
    Object.assign(this, init);
  }
}

export class ApprovalViewCloudConfig implements ComponentConfig {
  name = 'approval-view-cloud';
  permissionSets = undefined;
  title = 'key_approvalCheckCloud';
  type = 'approver'; // approver, requestor or emergency
  limit = 20;
  showAll = false;
  canApprove = true;
  defaultContent = 'detail'; // detail or response
  sortAttribute = 'RequestCreationTime';
  sortOrder = 'Descending';
  titleAttribute = '';
  contentAttribute = '';
  reasonRequired = false;
  includeDelegates = false;

  public constructor(init?: Partial<ApprovalViewCloudConfig>) {
    Object.assign(this, init);
  }
}

// #endregion

// #region frame-view

export class FrameViewConfig implements ComponentConfig {
  name = 'frame-view';
  permissionSets = undefined;
  title = 'key_frameView';
  componentID = '';
  paddingLeft = 0;
  paddingRight = 0;
  paddingTop = 0;
  paddingBottom = 0;
  data = '';

  public constructor(init?: Partial<FrameViewConfig>) {
    Object.assign(this, init);
  }
}

// #endregion
